<template>
  <div>
    <span class="quantily-customer"
      >Tìm thấy
      <span style="font-weight: 700">{{ total }}</span> khách
      hàng</span
    >
    <div id="customer-scroll-table" @scroll="scrollCustomers">
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr class="thead-customer">
              <th class="table-head-left">
                <v-checkbox v-model="selectAll" class="mr-9"></v-checkbox>

                <span> Khách hàng</span>
              </th>
              <th class="text-left">Điện thoại</th>
              <th class="text-left">Nhãn</th>
              <th class="text-left">Fanpage</th>
              <th class="text-left">Nhân viên</th>
              <th class="text-left">Fapage Scope ID</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="height: 54px"
              v-for="(item, index) in customers"
              :key="item._id"
            >
              <td class="table-head-left">
                <v-checkbox
                  v-model="selected"
                  :value="item._id"
                  number
                  class="mr-6"
                ></v-checkbox>
                <v-avatar width="30" height="30">
                  <img :src="item.avatar_url"
                /></v-avatar>
                {{ index + 1 + ".  " }}
                {{ item.name }}
              </td>
              <td>
                <div v-if="item.phones">{{ item.phones.phone }}</div>
              </td>
              <td>
                <div class="label-customers">
                  <div
                    class="label-customer"
                    v-for="(i, ind) in item.labels"
                    :key="ind"
                    :style="{ background: i.color }"
                  >
                   <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ i.label }}
                </div>
              </template>
              <span> {{ i.label }}</span>
            </v-tooltip>
  
                  </div>
                </div>
              </td>
              <td>{{ item.page_name }}</td>
              <td>{{ item.e_name ? item.e_name : '' }}</td>
              <td>{{ item.psid }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import { chatService } from "../../repositories/ChatService";
import ErpService from "../../repositories/ErpService";
import eventBus from "../../plugins/eventBus";
// import _ from "lodash";
export default {
  data() {
    return {
      selected: [],
      customers: [],
      total:0,
      page: 1,
      scroll: true,
      filter: []
    };
  },
  created() {
    this.getListCustomers();
    eventBus.$on("filter-keyword", (data) => {
      // console.log(data)
      // if(_.isEmpty(data)){
      //   this.scroll = true
      // }else {
      //   this.scroll = false
      // }
      this.filter = data;
      const params = {
        ...data,
      };
      this.getListCustomers(params);
      this.page = 1
    });
  },
  methods: {
    async getListCustomers(val) {
      let params = {
        limit: 20,
        page: this.page,
        ...val
      }
      if(val) {
        //delete params.limit
        delete params.page
      }
      const res = await chatService.getListCustomers(params);

      res.data.values.map((s) => {
        if (s.erp_phones && s.erp_phones.length) {
          const arrSort = s.erp_phones.sort(this.compareMoment);
          s.phones = arrSort[0];
        } else if(s.phones && s.phones.length){
          const arrSort = s.phones.sort(this.compareMoment);
          s.phones = arrSort[0];
        }
        if (s.erp_phones) {
          let phones = s.erp_phones.concat(s.phones);
          s.phones = phones[0];
        } else {
          s.phones = s.phones[0];
        }
      });

      this.customers = res.data.values;
      this.total = res.data.options.totalVuale
    },
    async scrollCustomers(event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 2 && this.scroll
      ) {
        this.page = this.page + 1
        let params = {
          page: this.page,
          limit: 20,
          ...this.filter
        };
        const res = await chatService.getListCustomers(params);
        if(res.data.values.length == 0) {
          return
        }
        res.data.values.map((s) => {
          if (s.erp_phones) {
            let phones = s.erp_phones.concat(s.phones);
            s.phones = phones[0];
          } else {
            s.phones = s.phones[0];
          }
        });
        this.customers = this.customers.concat(res.data.values);
      }
    },
    async getProducts() {
      const res = await ErpService.getProducts();
      this.product_group = res.data;
    },
  },
  computed: {
    selectAll: {
      get: function () {
        return this.customers
          ? this.selected.length == this.customers.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.customers.map(function (user) {
            selected.push(user.id);
          });
        }
        this.selected = selected;
      },
    },
  },
  beforeDestroy() {
    eventBus.$off("filter-keyword");
  },
};
</script>
<style lang="scss" scoped>
.table-head-left {
  display: flex;
  align-items: center;
  height: 54px !important;
}
.quantily-customer {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
}
.thead-customer {
  th {
    background: #fafafa !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #262626 !important;
  }
}
.label-customers {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
  text-align: center;
  .label-customer {
    margin: 2px;
    font-size: 10px;
    width: 61px;
    background: #fd7c23;
    text-transform: uppercase;
    height: 16px;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 4px;
    padding: 1px 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
