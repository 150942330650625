<template>
  <form @submit.prevent="fillterCustomers" class="fillter">
    <v-text-field
      placeholder="Tìm tên, số điện thoại"
      outlined
      solo
      flat
      dense
      v-model="fillter.keyword"
     
    ></v-text-field>
    <v-combobox
      v-model="product_group_id"
      :items="product_group"
      placeholder="Nhóm sản phẩm"
      outlined
      solo
      flat
      dense
      item-text="name"
      item-value="id"
      append-icon=""
      multiple
      clearable
      no-data-text="Không có dữ liệu"
      :menu-props="{ bottom: true, offsetY: true }"
    ></v-combobox>
    <v-select
      v-model="fillter.labels"
      :items="labels"
      item-text="label"
      item-value="label_id"
      placeholder="Lọc theo nhãn"
      outlined
      solo
      flat
      multiple
      clearable
      dense
      append-icon="mdi-chevron-down"
      no-data-text="Không có dữ liệu"
      :menu-props="{ bottom: true, offsetY: true }"
    ></v-select>
    <v-select
      v-model="fillter.fanpage"
      :items="pages"
      item-text="name"
      item-value="page_id"
      placeholder="Lọc theo Fanpage"
      outlined
      solo
      flat
      multiple
      clearable
      dense
      append-icon="mdi-chevron-down"
      no-data-text="Không có dữ liệu"
      :menu-props="{ bottom: true, offsetY: true }"
    ></v-select>
     <v-select
          v-model="fillter.e_ids"
          :items="users"
          item-text="fullName"
          item-value="id"
          dense
          outlined
          solo
          flat
          multiple
          placeholder="Nhân viên trực page"
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
          no-data-text="Không có dữ liệu"
          clearable
        ></v-select>
    <div id="date-customer">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            placeholder="Ngày bắt đầu ~ Ngày kết thúc"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
            solo
            flat
            dense
          ></v-text-field>
        </template>

        <v-date-picker v-model="dates" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <div @click="dates = []" v-show="dates.length> 0" class="clear-dates"><v-icon>mdi-close</v-icon></div>
    </div>
    
    <v-btn  type="submit" color="#009FE0" class="white--text ml-3" style="margin-top: 2px"
      >Tìm kiếm</v-btn
    >
  </form>
</template>
<script>
import ErpService from "../../repositories/ErpService";
import { chatService } from "../../repositories/ChatService";
import { pageService } from "../../repositories/PageService";
import eventBus from "../../plugins/eventBus";
import moment from "moment"
export default {
  data: () => ({
    dates: [],
    menu: false,
    product_group: [],
    labels: [],
    pages: [],
    fillter: {
      keyword: null,
      product_group_id: null,
      labels: null,
      fanpage: null,
      e_ids: null,
      start_date: null,
      end_date:null
    },
    product_group_id: null,
    users: [],
  }),
  created() {
    this.getProducts();
    this.getLabels();
    this.getListPages();
    this.getUsers();
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    async getProducts() {
      const res = await ErpService.getProducts();
      this.product_group = res.data;
    },
    async getUsers() {
      const res = await ErpService.getUsers();
      this.users = res.data;
    },
    getLabels() {
      chatService
        .getLabels()
        .then((res) => {
          this.labels = res.data;
          this.labels.map((s) => {
             s.label = s.label.toUpperCase()
          })
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    async getListPages() {
      let params = {
        limit: 500,
        assign: true,
      };
      const res = await pageService.getListPages(params);
      this.pages = res.data;
    },
    fillterCustomers() {
      if (this.product_group_id && this.product_group_id.length > 0) {
        this.fillter.product_group_id = this.product_group_id.map((s) => {
          return s.id;
        });
      }else {
        delete this.fillter.product_group_id
      }
      if(!this.fillter.keyword || this.fillter.keyword == ''){
        delete this.fillter.keyword
      }
       if(!this.fillter.products || this.fillter.products == ''){
        delete this.fillter.products
      }
       if(!this.fillter.labels || this.fillter.labels == ''){
        delete this.fillter.labels
      }
       if(!this.fillter.fanpage || this.fillter.fanpage == ''){
        delete this.fillter.fanpage
      }
      if(!this.fillter.e_ids || this.fillter.e_ids == '') {
        delete this.fillter.e_ids
      }
      if(this.dates.length > 0) {
        this.fillter.start_date = this.formatDate(this.dates[0]);
        this.fillter.end_date = this.formatDateEnd(this.dates[1])
      }else{
        delete this.fillter.start_date
        delete this.fillter.end_date 
      }
      eventBus.$emit("filter-keyword", this.fillter);
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    formatDateEnd(date) {
      return moment(date).endOf("day").format('YYYY-MM-DD HH:mm:ss')
    }
  },
};
</script>
<style lang="scss" scoped>
.fillter {
  display: flex;
  max-width: 1300px;
  flex-wrap: wrap;
  text-align: left;
}
</style>
<style lang="scss">
.fillter {
  label.v-label.theme--light {
    font-size: 14px !important;
  }
  .v-input__slot {
    width: 175px;
  }
  #date-customer {
    position: relative;
    .clear-dates{
      position: absolute;
          right: 2px;
    top: 7px;
    cursor: pointer;
    }
    .v-input__slot {
      width: 250px;
    }
  }
}
</style>
